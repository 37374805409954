import constant from "./constants";
import axios from 'axios'

var service = {};

var token = JSON.parse(localStorage.getItem('token'));
var userData = JSON.parse(localStorage.getItem('user'));

export var setToken = (tokenData) => {
    return token = tokenData;
}

export var getToken = () => {
    return token;
}

export var setUserData = (user) => {
    return userData = user;
}

export var getUserDetails = () => {
    return userData;
}

export var logoutAPI = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    return fetch(constant.API_URL + "/api/auth/logout", {
        method: "POST",
        body: JSON.stringify({
            username: JSON.parse(localStorage.getItem('userName')),
            ip: res.data.ip,
        }),
        headers: { "Content-Type": "application/json" },
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        );
};

export var logout = async () => {
    try {
        let result = await logoutAPI(userData)
        if (result.success == true) {
            let orgId = localStorage.getItem("orgId")
            localStorage.clear();
            if (orgId == 99 && window.location.href.includes("admin.assesshub.com")) {
                window.location.replace('/#/auth/jfs/signin')
            } else {
                window.location.replace('/#/auth/signin')
            }
            return { success: true }
        } else {
            return { success: false }
        }
    } catch (err) {
        console.log(err)
        return { success: false }
    }
};

export default service;