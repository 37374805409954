export let localhostConstants = {
  name: "localhost",
  publicTestLibrary_orgid: 2,
  urlUniqueString: "localhost",
  url: "http://localhost:4002",
  botFrontUrl: "http://localhost:3000",
  botBackUrl: "http://localhost:10000",
  frontendUrl: "http://localhost:3000",
  userFrontendUrl: "http://localhost:3001",
  userBackendUrl: "http://localhost:3006",
  botBuilderFrontUrl: "https://botbuilder.myassesshub.com",
  botBuilderBackUrl: "https://botbuilder-api.myassesshub.com",
};

export let serverConstants = [
  {
    name: "v1-production",
    publicTestLibrary_orgid: 4,
    urlUniqueString: "admin.assesshub.in",
    url: "https://admin-api.assesshub.in",
    // botFrontUrl: "https://botdashboardv2.assesshub.in",
    // botBackUrl: 'https://botdashboard-api.assesshub.in'
    frontendUrl: "https://admin.assesshub.in",
    userFrontendUrl: "https://user.assesshub.in",
    userBackendUrl: "https://api-user.assesshub.in",
  },
  {
    name: "v1-production-old",
    publicTestLibrary_orgid: 4,
    urlUniqueString: "admin.assesshub.co.in",
    url: "https://admin-api.assesshub.co.in",
    // botFrontUrl: "https://botdashboardv2.assesshub.co.in",
    // botBackUrl: 'https://botdashboard-api.assesshub.co.in'
    frontendUrl: "https://admin.assesshub.co.in",
    userFrontendUrl: "https://user.assesshub.co.in",
    userBackendUrl: "https://api-user.assesshub.co.in",
  },
  {
    name: "v2-production",
    publicTestLibrary_orgid: 2,
    urlUniqueString: "admin.assesshub.com",
    url: "https://admin-api.assesshub.com",
    botFrontUrl: "https://botdashboard.assesshub.com",
    botBackUrl: "https://botdashboard-api.assesshub.com",
    frontendUrl: "https://admin.assesshub.com",
    userFrontendUrl: "https://user.assesshub.com",
    userBackendUrl: "https://api-user.assesshub.com",
    botBuilderFrontUrl: "https://botbuilder.assesshub.com",
    botBuilderBackUrl: "https://botbuilder-api.assesshub.com",
  },
  {
    name: "v2-jumpbox-production",
    publicTestLibrary_orgid: 2,
    urlUniqueString: "admin.assesshubdirect.com",
    url: "https://admin-api.assesshubdirect.com",
    botFrontUrl: "https://botdashboard.assesshubdirect.com",
    botBackUrl: "https://botdashboard-api.assesshubdirect.com",
    frontendUrl: "https://admin.assesshubdirect.com",
    userFrontendUrl: "https://user.assesshubdirect.com",
    userBackendUrl: "https://api-user.assesshubdirect.com",
    botBuilderFrontUrl: "https://botbuilder.assesshub.com",
    botBuilderBackUrl: "https://botbuilder-api.assesshub.com",
  },
  {
    name: "v2-staging",
    publicTestLibrary_orgid: 2,
    urlUniqueString: "admin.myassesshub.com",
    url: "https://admin-api.myassesshub.com",
    botFrontUrl: "https://botdashboard.myassesshub.com",
    botBackUrl: "https://botdashboard-api.myassesshub.com",
    frontendUrl: "https://admin.myassesshub.com",
    userFrontendUrl: "https://user.myassesshub.com",
    userBackendUrl: "https://api-user.myassesshub.com",
    botBuilderFrontendUrl: "https://botbuilder.myassesshub.com",
    botBuilderFrontUrl: "https://botbuilder.myassesshub.com/",
    botBuilderBackUrl: "https://botbuilder-api.myassesshub.com/",
  },
  {
    name: "v2-staging-2",
    publicTestLibrary_orgid: 2,
    urlUniqueString: "admin2.assesshub.com",
    url: "https://admin2-api.assesshub.com",
    botFrontUrl: "https://botdashboard.assesshub.com",
    botBackUrl: "https://botdashboard-api.assesshub.com",
    frontendUrl: "https://admin2.assesshub.com",
    userFrontendUrl: "https://user.assesshub.com",
    userBackendUrl: "https://api-user.assesshub.com",
    botBuilderFrontendUrl: "https://botbuilder.myassesshub.com",
    botBuilderFrontUrl: "https://botbuilder.myassesshub.com/",
    botBuilderBackUrl: "https://botbuilder-api.myassesshub.com/",
  },
];
