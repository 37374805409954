import { logout } from "./service/dataService";

export const fetchInterceptor = () => {
    const originalFetch = fetch;

    fetch = async (...args) => {
        const response = await originalFetch(...args);
        if (response.status == 401) {
            let result = await logout()
            if (result.success == false) {
                return false
            }
            localStorage.setItem("sessionStatus", "expired")
        }
        return response;
    };
};